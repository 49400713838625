@import '/theme.css';

.component > .grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: calc(sizeSm / -2) calc(sizeXl / -2);

  & > * {
    flex: 1 0 auto;
    margin: calc(sizeSm / 2) calc(sizeXl / 2);
  }

  & > .numberPad {
    width: calc(60% - sizeXl);
  }

  & > .valueAndAmount {
    width: calc(40% - sizeXl);
    min-width: 15rem;
  }
}

.valueAndAmount {
  display: flex;
  flex-direction: column;

  & > .value {
    margin-bottom: sizeXs;
  }

  & > .amount {
    margin-top: auto;
  }
}
