@import '/theme.css';

.component {
  background-color: cWhite;
  border-radius: borderRadius;
  box-shadow: inset 0 0 0 1px cGray;
  transition: box-shadow easeOutProductive durationS;

  &.focused {
    box-shadow: inset 0 0 0 1px cBlue;
  }

  &.selected {
    box-shadow: inset 0 0 0 2px cBlue;
  }

  &.cautioned {
    box-shadow: inset 0 0 0 1px cRed;
  }

  &.selected.cautioned {
    box-shadow: inset 0 0 0 2px cRed;
  }
}
