@import '/theme.css';

.productLine {
  & > :not(:last-child) {
    margin-bottom: sizeX4s;
  }
}

.componentProductMeta {
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: sizeX2s;
  }
}

.productInfo {
  display: flex;
  padding: 0;
  list-style: none;

  & > :not(:last-child) {
    margin-right: sizeX3s;
  }
}

.componentProductHeader {
  font-weight: normal;
  font-size: fontSizeM2;
}

.componentProductInfoItem {
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: sizeX4s;
  }

  & > .productInfoText {
    margin-right: sizeX3s;
  }
}
