/* Fonts */
@value fontFamilyBase: 'Graphik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
@value fontFamilyAlt: 'Produkt', Georgia, Times, 'Times New Roman', serif;
@value fontFamilyScript: 'Journal', sans-serif; /* font for sidenote */

/* Sizes */
@value fontSizeX2s: 1.4rem;
@value fontSizeXs: 1.6rem;
@value fontSizeS: 1.8rem;
@value fontSizeM: 2rem;
@value fontSizeM2: 2.2rem;
@value fontSizeL: 2.5rem;
@value fontSizeXl: 3.5rem;
@value fontSizeX2l: 4.5rem;
@value fontSizeX3l: 5.5rem;
@value fontSizeX4l: 6.5rem;
@value fontSizeX5l: 8rem;

/* Leading */
@value lineHeightHeading: 1.1;
@value lineHeight: 1.777;
@value lineHeightSm: 1.4;

/* Weights */
@value fontWeightLight: 300;
@value fontWeightRegular: 400;
@value fontWeightSemiBold: 600;
@value fontWeightBold: 800;

:root {

  --headingBase: {
    line-height: lineHeightHeading;
    margin-bottom: sizeXxs;
    margin-top: sizeMd;
  }

  --heading1: {
    font-family: fontFamilyAlt;
    font-weight: fontWeightBold;
    font-size: fontSizeX2l;

    @media viewportLg {
      font-size: fontSizeX3l;
    }

    @media viewportXl and verticalViewportLg {
      font-size: fontSizeX4l;
    }

    @media viewportX2l and verticalViewportLg {
      font-size: fontSizeX5l;
    }
  }

  --heading2: {
    font-family: fontFamilyAlt;
    font-weight: fontWeightBold;
    font-size: fontSizeXl;
    line-height: lineHeightHeading;

    @media viewportLg and verticalViewportMd {
      font-size: fontSizeX2l;
    }

    @media viewportXl and verticalViewportLg {
      font-size: fontSizeX3l;
    }
  }

  --heading3: {
    font-family: fontFamilyAlt;
    font-weight: fontWeightBold;
    font-size: fontSizeL;
    line-height: lineHeightHeading;

    @media viewportXl and verticalViewportLg {
      font-size: fontSizeXl;
    }
  }

  --heading4: {
    font-family: fontFamilyAlt;
    font-weight: fontWeightBold;
    font-size: fontSizeM;
    line-height: lineHeightHeading;

    @media viewportXl and verticalViewportLg {
      font-size: fontSizeL;
    }
  }
}
