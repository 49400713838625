@import '/theme.css';

.component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;

  & > .linksFooter {
    width: 100%;
    flex: 1 1 auto;
  }

  & > .content {
    width: 60%;
    flex: 1 1 auto;

    @media viewportMd {
      width: 50%;
    }
  }

  & > .imageContainer {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    flex: 0 1 auto;

    @media viewportMd {
      width: 50%;
    }
  }
}

.content > :not(:last-child) {
  margin-bottom: sizeXs;
}

.componentLinks {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  align-items: flex-start;

  @media viewportMd {
    flex-flow: column nowrap;
  }

  & > * {
    flex-shrink: 0;
  }
}
