
@import '/theme.css';

.component {
  position: relative;
  z-index: 0;
  padding-top: sizeXl;
  padding-bottom: 0;
  overflow-x: hidden;

  & > :not(:last-child) {
    margin-bottom: sizeXl;
  }

  & > .sticky {
    position: absolute;
    bottom: sizeXl;
    width: 100%;
  }

  @media viewportLg {
    display: flex;
    padding-top: 0;
    padding-bottom: 0;

    & > * {
      width: 50%;
      flex: 0 0 auto;
    }

    & > :not(:last-child) {
      margin-bottom: 0;
    }

    &:not(.visible) > .content {
      pointer-events: none;
    }

    & > .content {
      /* Top & bottom are set through JavaScript */
      min-height: 100vh;
      position: sticky;
      z-index: 1;
    }

    & > .aside {
      position: relative;
      z-index: 2;
    }
  }
}

.stickyCta {
  display: flex;
  justify-content: flex-start;
}

.content {
  align-items: center;

  @media viewportLg {
    padding-top: sizeX3l;
    padding-bottom: sizeX3l;
    display: flex;
  }

  & > .textContainer {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(containerWidthLg + gutterS * 2);

    @media viewportMd {
      max-width: calc(containerWidthLg + gutterM * 2);
    }

    @media viewportLg {
      margin-right: 0;
      max-width: calc(containerWidthLg / 2 + gutterL);
    }
  }
}

.aside {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  padding-top: 240px;
  padding-bottom: 0;

  @media viewportSm {
    padding-top: 0;
    align-items: stretch;
  }

  @media viewportLg {
    padding-right: calc(3 * gutterL);
    padding-top: headerHeight;
  }

  @media viewportLg and verticalViewportMd {
    padding-left: gutterL;
    padding-right: gutterL;
  }

  & > * {
    flex: 0 1 auto;
  }

}

.image {
  object-fit: contain;
}

.textContainer {
  padding-left: gutterS;
  padding-right: gutterS;

  @media viewportMd {
    padding-left: gutterM;
    padding-right: gutterM;
  }

  @media viewportLg {
    padding-left: gutterL;
    padding-right: 0;
  }
}
