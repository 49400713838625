@import '/theme.css';
@import '/utilities.css';

.component {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 9rem;
  padding: sizeXs sizeXs sizeXs 13.5rem;
  border-radius: borderRadius;
  background-color: cWhite;
  transition: box-shadow easeOutProductive durationS;
  color: inherit;
  line-height: 1;

  & > .thumb {
    position: absolute;
    width: 12rem;
    height: 8rem;
    left: 0;
    top: 0;
  }

  & > .input {
    @apply --visuallyHidden;
  }

  & > .label {
    position: static;
    margin-bottom: sizeX4s;
  }

  &.checked > .label::before {
    box-shadow: inset 0 0 0 2px cBlue;
  }

  /* The checkbox */

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
  }

  &::before {
    right: sizeX3s;
    top: sizeX3s;
    width: 1.9rem;
    height: 1.9rem;
    box-shadow: inset 0 0 0 1px cGray;
    transition: box-shadow easeOutProductive durationS;
    background-color: cWhite;
    border-radius: borderRadiusSmall;
  }

  &::after {
    right: calc(sizeX3s -.3rem);
    top: calc(sizeX3s -.3rem);
    width: 2.5rem;
    height: 2.5rem;
    transition: transform easeOutExpressive durationS, opacity easeOutProductive durationS;
    background-image: url(/icons/icon-checkbox.svg);
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0;
  }

  &.checked::before {
    box-shadow: inset 0 0 0 2px transparent;
  }

  &.checked::after {
    opacity: 1;
  }

  & a {
    position: relative;
    z-index: 1;
  }
}

.thumb {
  padding: sizeX4s;

  & > img {
    width: auto;
    height: auto;
    max-height: 8rem;
    margin: 0 auto;
  }
}

.text {
  padding: sizeX2s;

  & > .link {
    margin-bottom: sizeX4s;
  }
}

.label {
  position: relative;
  display: block;
  font-size: fontSizeS;
  transition: color easeOutProductive durationXs;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: borderRadius;
    box-shadow: inset 0 0 0 1px cGray;
    transition: box-shadow easeOutProductive durationS;
  }

  &:--enter::before {
    box-shadow: inset 0 0 0 1px cBlue;
  }
}

.checked .label {
  color: cBlue;
  font-weight: fontWeightSemiBold;
}

.description {
  font-size: fontSizeXs;
  color: cGray;
}
