@import '/theme.css';

.component {
  position: relative;
  z-index: 0;
  padding: 1px 0 sizeXl;

  @media viewportLg {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;

    @supports (display: grid) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      padding-bottom: 10vh;
    }
  }

  & > * {
    @media viewportLg {
      flex-shrink: 0;

      @supports (display: grid) {
        min-width: 0;
      }
    }
  }

  & > .content {
    margin-top: sizeLg;

    @media viewportLg {
      min-height: 100vh;
      z-index: 2;
      width: 50.1%;
      margin-top: 0;

      @supports (display: grid) {
        width: 100%;
      }
    }
  }

  & > .aside {
    margin-bottom: sizeLg;

    @media viewportLg {
      position: relative;
      z-index: 1;
      width: 49.9%;
      margin-bottom: 0;

      @supports (display: grid) {
        width: 100%;
      }
    }
  }

  & > .scrollMarker {
    width: 100%;

    @media viewportLg {
      @supports (display: grid) {
        grid-column: 1 / -1;
      }
    }
  }

  & > .background {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: cPink;
    transition: background-color easeInOutProductive durationM;
    will-change: transform;

    @supports (display: grid) {
      grid-column: 1 / -1;
    }
  }

  & > .sticky {
    @supports (display: grid) {
      grid-column: 1 / -1;
    }

    @supports (position: sticky) {
      bottom: sizeXl;
      margin-top: sizeXl;
      z-index: 3;
      width: 100%;
    }
  }
}

.sticky {
  display: none;

  @supports (position: sticky) {
    display: flex;
    align-items: flex-end;
    position: sticky;
  }
}

.stickyCta {
  display: flex;
  justify-content: flex-start;
}

.componentContent {
  position: sticky;
  display: flex;
  align-items: center;

  @media viewportLg {
    padding-top: sizeX3l;
    padding-bottom: sizeX3l;
    display: flex;
  }

  & > .inner {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(containerWidthLg + gutterS * 2);

    @media viewportMd {
      max-width: calc(containerWidthLg + gutterM * 2);
    }

    @media viewportLg {
      margin-right: 0;
      max-width: calc(containerWidthLg / 2 + gutterL);
    }
  }
}

.inner {
  padding-left: gutterS;
  padding-right: gutterS;

  @media viewportMd {
    padding-left: gutterM;
    padding-right: gutterM;
  }

  @media viewportLg {
    padding-left: gutterL;
    padding-right: 0;
  }
}

.componentAside {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
  padding-top: sizeSm;
  padding-bottom: sizeSm;

  @media viewportLg {
    padding-left: gutterL;
    padding-right: gutterL;
  }

  & > * {
    flex: 0 1 auto;
  }

  & > .cta {
    position: absolute;
    top: sizeSm;
    right: sizeSm;

    @media viewportLg {
      top: calc(headerHeight + sizeXl);
      right: sizeXl;
    }
  }
}

