@import '/theme.css';

.componentForm {
  & > :not(:last-child) {
    margin-bottom: sizeSm;
  }

  & > .formLabel {
    margin-bottom: sizeX2s;
  }
}

.formLabel {
  display: block;
  font-weight: normal;
}

.form {
  & > .formSection:not(:last-child) {
    margin-bottom: sizeXs;
  }
}

.formSection {
  &:not(:first-child) {
    border-top: 1px solid cGray;
    padding-top: sizeXs;
  }

  & > :not(:last-child) {
    margin-bottom: sizeX3s;
  }
}

.footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  & > :first-child:last-child {
    margin-right: auto;
  }
}
