@import '/theme.css';
@import '/utilities.css';

.input {
  @apply --visuallyHidden;

  & + .label {
    display: block;
    padding: sizeXs;
    box-shadow: inset 0 0 0 1px cGray;
    border: 0;
    border-radius: borderRadiusSmall;
    line-height: 1.8rem;
    font-size: fontSizeL;
    font-family: fontFamilyAlt;
    font-weight: fontWeightBold;
    appearance: none;
    background: white;
    transition: box-shadow easeOutProductive durationS, background easeOutProductive durationS;
    text-align: center;
    color: cBlack;
    cursor: pointer;
  }

  &:checked + .label {
    background: cBlue;
    box-shadow: inset 0 0 0 2px cBlue;
    color: cWhite;
    transition: box-shadow easeOutExpressive durationM, background easeOutExpressive durationM;
  }
}
