@import '/theme.css';

.component {
  width: 220px !important;
  height: 220px !important;
  text-align: center;
  color: cWhite;

  @media viewportLg {
    width: 275px !important;
    height: 275px !important;
  }

  & > .circle {
    width: 100%;
    height: 100%;
    margin: auto;
  }
}

.circle {
  background-color: cBlueDark;
  border-radius: 50%;
  position: relative;

  & > .circleBackground {
    content: '';
    position: absolute;
    z-index: -1;
    top: -2.5%;
    left: -2.5%;
    width: 105%;
    height: 105%;
    background-color: cBlueDark;
    border-radius: 275px;
    transform: scale(calc(100 / 105));
  }

  & > .content {
    flex: 1 1 auto;
    height: 100%;
    width: 100%;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: sizeMd;
  border-radius: 50%;
  overflow: hidden;

  & > .title {
    margin-bottom: sizeX3s;

    @media viewportMd {
      margin-bottom: sizeX2s;
    }
  }

  & > .link {
    position: static;
    text-align: center;
    white-space: nowrap;
  }

  & > * {
    margin-top: 0;
  }
}

.title {
  font-family: fontFamilyAlt;
  font-weight: fontWeightBold;
  font-size: fontSizeS;
  line-height: lineHeightHeading;
  text-align: center;
  color: cWhite;

  @media viewportLg {
    font-size: fontSizeM2;
  }
}
