@import '/theme.css';

.component {
  & > :first-child {
    @media viewportLg {
      margin-top: calc(-1 * headerHeight);
    }
  }
}

.sectionWithCta {
  position: relative;

  & > .cta {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.cta {
  position: relative;

  & > * {
    width: 100%;
    position: absolute;
    bottom: sizeXs;

    @media viewportMd {
      bottom: sizeLg;
    }
  }
}

.hero {
  background-color: cPink;
  position: relative;
  z-index: 0;

  & > .notificationMessage {
    @media viewportLg {
      position: absolute;
      z-index: 1;
    }
  }

  & > .heroInner {
    @media viewportLg {
      margin-top: headerHeight;
    }
  }
}

.ctaBadge {
  display: flex;
}

.quotation {
  & > .quotationForm {
    margin-bottom: sizeX2l;
    max-width: 66rem;
  }

  @media viewportLg {
    display: flex;
    flex-wrap: wrap;

    & > * {
      flex: 0 0 auto;
      width: calc(50% - gutterL);
    }

    & > .quotationForm {
      margin-left: gutterL;
      margin-bottom: 0;
    }

    & > .quotationLinksAside {
      margin-top: auto;
    }
  }

  @supports (display: grid) {

    @media viewportLg {
      display: grid;
      grid-gap: gutterL;
      grid-template:
        'intro form'
        'links form';
      grid-template-columns: 1fr 1fr;

      & > * {
        width: auto;
      }

      & > .quotationIntro {
        grid-area: intro;
      }

      & > .quotationForm {
        grid-area: form;
        width: auto;
        margin: 0;
        padding-top: sizeX2s;
      }

      & > .quotationLinksAside {
        align-self: end;
        margin-top: 0;
        grid-area: links;
      }
    }
  }
}

.quotationForm {
  & > :not(:last-child) {
    margin-bottom: sizeXs;
  }
}

.quotationIntro {
  & > img {
    display: none;
  }

  @media viewportLg {
    & > img {
      display: block;
    }
  }
}

.form {
  & > :not(:last-child) {
    margin-bottom: sizeLg;
  }

  & > .amountDue {
    width: 33.5rem;
    max-width: 100%;
    margin-left: auto;
  }
}

.heroTitleThin {
  font-weight: normal;
}

.footnote {
  font-size: fontSizeX2s;
  font-style: italic;
  line-height: lineHeightSm;
}

.componentNotificationMessage {
  background-color: cYellow;
  color: cBlack;
  width: 100%;
  padding: sizeXs 0;
  text-align: center;
}
