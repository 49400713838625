@import '/theme.css';

.componentBase {
  display: flex;
  align-items: flex-end;

  & > .slide {
    width: 100%;
    flex: 0 0 auto;
  }

  & > .slide:not(:first-child) {
    margin-left: -100%;
  }
}

.componentWithBullets {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .slides {
    width: 100%;
    max-width: 66vw;
  }

  & > .bullets {
    margin-top: sizeX2s;
  }
}

.slide {
  & > * {
    margin: auto;
  }
}

.image {
  outline: none;
}
