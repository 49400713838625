@import '/theme.css';

.componentBase {
  padding: sizeXs;

  @media viewportLg {
    padding: sizeLg;
  }
}

.componentYellow {
  background-color: cYellow;
  color: cBlueDark;
}

.componentBlue {
  background-color: cBlueDark;
  color: cWhite;
}

.componentPink {
  background-color: cPink;
  color: cBlueDark;
}
