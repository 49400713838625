@import '/theme.css';

.component {
  background-color: cYellow;
  color: cGrayDark;
  padding: sizeXs;
  border-radius: borderRadius;

  & a {
    color: inherit;
    text-decoration: underline;
  }

  &.error {
    background-color: cRed;
    color: cWhite;
  }
}
