@import '/theme.css';

.component {
  display: flex;
  align-items: center;
  border-radius: borderRadius;
  background-color: cWhite;
  box-shadow: boxShadowDefault;
  color: inherit;
  line-height: 1;
  max-width: 100%;

  & > .thumb {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    margin: sizeX4s;
  }

  & > .text {
    flex: 1 1 auto;
  }
}

.thumb {
  transform-origin: 0 50%;

  & > img {
    max-width: 9.5rem;
    width: auto;
    height: auto;
  }
}

.text {
  display: flex;
  align-items: center;
  padding: sizeX2s;

  & > .icon {
    margin-left: calc(sizeX2s - 26px); /* 26px is the icon width */
  }
}

.link {
  display: block;
  font-weight: fontWeightSemiBold;
  font-size: fontSizeS;
  color: cBlue;
  transition: color easeOutProductive durationXs;

  &:hover {
    color: cBlueDark;
  }
}

.icon {
  color: cBlue;
}

.subtext {
  color: cGray;
  font-size: fontSizeX2s;
}
