@import '/theme.css';

.component {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  font-size: fontSizeS;
}

.amount {
  position: relative;
  font-size: fontSizeL;
  font-family: fontFamilyAlt;
  font-weight: fontWeightBold;
  color: cBlueDark;
  white-space: nowrap;

  /* Position absolute is applied during transitions. When applied, this component needs to be held in place. */
  & > * {
    right: 0;
    top: 0;
  }
}
