@import '/theme.css';

.component {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  padding: sizeX2s;
  color: inherit;
  line-height: 1;

  & > .thumb {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    margin: sizeX4s;
  }

  & > .text {
    margin-left: sizeX2s;
    margin-right: sizeXs;
    flex: 1 1 auto;
  }

  & > .select {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > .remove {
    position: relative;
    z-index: 2;
  }

  &:--enter > .remove {
    opacity: 1;
  }
}

.thumb {
  & > img {
    max-width: 4.5rem;
    width: auto;
    height: auto;
  }
}

.text {
  & > .label {
    margin-bottom: sizeX4s;
  }
}

.sublabel {
  color: cGray;
  font-size: fontSizeX2s;
}

.remove {
  color: cBlueDark;
  transition: color durationXs;

  &:--enter {
    color: cRed;
  }

  @media (pointer: fine) {
    opacity: 0;
    transition: color durationS, opacity durationXs;
  }
}
