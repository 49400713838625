@import '/theme.css';
@import '/utilities.css';

.component {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: borderRadius;
  background-color: cBackground;
  color: inherit;
  line-height: 1;
  padding: sizeXs;
  cursor: pointer;
  transition: background-color easeOutProductive durationS, color easeOutProductive durationS;

  &.checked {
    background-color: cBlue;
    color: cWhite;
    transition: background-color easeOutExpressive durationM, color easeOutExpressive durationM;
  }

  & > .label::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.input {
  @apply --visuallyHidden;
}

.labelText {
  display: block;
  font-size: fontSizeS;
  position: relative;
  padding-left: sizeMd;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
  }

  &::before {
    left: 0;
    top: 0.5em;
    transform: translateY(-50%);
    width: 1.9rem;
    height: 1.9rem;
    box-shadow: inset 0 0 0 1px cGray;
    transition: box-shadow easeOutProductive durationS, background-color easeOutProductive durationS;
    background-color: cWhite;
    border-radius: borderRadiusSmall;
  }

  &::after {
    left: -0.3rem;
    top: 0.5em;
    transform: translateY(-50%);
    width: 2.5rem;
    height: 2.5rem;
    transition: transform easeOutExpressive durationS, opacity easeOutProductive durationS;
    background-image: url(/icons/icon-checkbox-inverted.svg);
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0;
  }
}

.checked .labelText::before {
  box-shadow: inset 0 0 0 2px transparent;
  background-color: transparent;
}

.checked .labelText::after {
  opacity: 1;
}
