@import '/theme.css';

.component {
  display: inline-flex;
  position: relative;
  z-index: 0;

  & > .indicator {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 10px;
  }

  & > .bullet:not(:last-child) {
    margin-right: 16px;
  }
}

.bullet {
  position: relative;
  background-color: cWhite;
  border: 0;
  display: block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  outline: none;
  padding: 0;
  cursor: pointer;
  transition: opacity easeInOutProductive durationS;

  &:focus {
    outline: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: -8px;
    bottom: -10px;
    left: -8px;
  }
}

.indicator {
  background-color: cBlue;
  border-radius: 5px;
}
