@import '/theme.css';

.component {
  & > *:not(:last-child) {
    margin-bottom: sizeLg;
  }
}

.componentGroup {
  & > .heading {
    margin-bottom: sizeX3s;
  }
}
