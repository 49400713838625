.component_root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #fff;
  font-size: 14px;
  box-shadow: 0px 4px 4px 0px #DCEEFF80;
  position: fixed;
  gap: 20px;
  left: 16px;
  right: 16px;
  bottom: 16px;

  @media screen and (min-width: 1024px) {
    font-size: 16px;
    left: 64px;
    bottom: 64px;
    max-width: 750px;
  }

  & > .button {
    flex-shrink: 0;
  }
}

.content {
  & a {
    color: #0000ff;

    &:hover {
      text-decoration: underline;
    }
  }
}
