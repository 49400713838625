@import '/theme.css';

.component {
  & > .layout {
    margin: calc(sizeSm / -2);

    @media viewportMd {
      @supports (display: grid) {
        margin: 0;
      }
    }
  }
}

.layout {
  & > * {
    margin: calc(sizeSm / 2);
    min-height: 180px;

    @media viewportMd {
      @supports (display: grid) {
        margin: 0;
      }
    }
  }

  @media viewportMd {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    & > .toolkit,
    & > .contact {
      width: calc(50% - sizeSm);
    }

    & > .newsletter {
      width: 100%;
    }

    @supports (display: grid) {
      display: grid;
      grid-gap: sizeSm;
      grid-template-areas: 'newsletter toolkit' 'newsletter contact';
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;

      & > .newsletter {
        grid-area: newsletter;
        width: 100%;
        align-self: stretch;
      }

      & > .toolkit {
        grid-area: toolkit;
        width: 100%;
      }

      & > .contact {
        grid-area: contact;
        width: 100%;
      }
    }
  }
}

.newsletter,
.toolkit,
.contact {
  & > * {
    min-height: 100%;
  }
}
