@import '/theme.css';

.component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;

  & > .linksFooter {
    width: 100%;
    padding: 0 sizeXs sizeXs;
    flex: 1 1 auto;
  }

  & > .content {
    align-self: center;
    margin-bottom: sizeXs;
  }

  & > .imageContainer {
    display: none;

    @supports (display: flex) {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: auto;
      margin-bottom: calc(-1 * sizeXs);

      @media viewportLg {
        margin-bottom: calc(-1 * sizeLg);
      }
    }
  }
}

.content {
  & > :not(:last-child) {
    margin-bottom: sizeXs;
  }
}

.componentForm {
  @media viewportSm {
    display: flex;
    align-items: flex-start;
  }

  & > * {
    width: 100%;
    max-width: 320px;
    flex: 0 0 auto;

    @media viewportSm {
      width: auto;
    }
  }

  & > :not(:last-child) {
    margin-bottom: sizeX3s;

    @media viewportSm {
      margin-bottom: 0;
    }
  }

  & > .formField {
    flex: 1 1 auto;
  }
}
