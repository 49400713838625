@import '/theme.css';

.component {
  & > .grid {

    /* Fukol grid: https://github.com/Heydon/fukol-grids */
    display: flex;
    flex-wrap: wrap;
    margin: calc(sizeX3s / -2);

    & > * {
      width: calc(50% - sizeX3s);
      min-width: 8rem;
      flex: 1 0 auto;
      margin: calc(sizeX3s / 2);
    }
  }

  & > .label {
    margin-bottom: sizeX4s;
  }
}

.label {
  display: block;
  padding: 0;
  cursor: pointer;
  font-size: fontSizeS;
  line-height: lineHeightSm;

  &.disabled {
    color: cGray;
    cursor: default;
  }
}
