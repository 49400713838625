@import '/theme.css';

.component {
  font-size: fontSizeXs;

  & > :not(:last-child) {
    display: block;
  }
}

