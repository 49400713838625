@import '/theme.css';
@import '/utilities.css';

.headingAndControlsWrapper {
  display: flex;
  justify-content: space-between;
}

.heading {
  @apply --heading4;

  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: fontSizeS;
  font-weight: fontWeightSemiBold;
  color: cBlueDark;
  transition: color easeOutProductive durationS;

  &.disabled {
    color: cGray;
    cursor: default;
  }

  @media viewportMd {
    font-size: fontSizeM;
  }
}

.content {
  padding: sizeXs 0;

  & > :last-child {
    margin-bottom: 0;
  }
}

.icon {
  transition: transform easeInOutExpressive durationS;
  color: cGray;
}
