@import '/theme.css';

.componentForm {
  & > .formLabel {
    margin-bottom: sizeX2s;
  }

  & > :not(:last-child) {
    margin-bottom: sizeSm;
  }
}

.formLabel {
  display: block;
  font-weight: normal;
}

.subtotal {
  display: flex;
  justify-content: space-between;
}

.form {
  & > :not(:last-child) {
    margin-bottom: sizeX2s;
  }
}

.physicalOrDigital {
  display: flex;

  & > * {
    width: calc(50% - sizeX3s / 2);
    flex: 0 0 auto;
  }

  & > :last-child {
    margin-left: sizeX3s;
  }
}

