@import '/theme.css';

.component {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  background-color: cYellow;
  min-height: 100vh !important;
  overflow: hidden;
  animation: thank-you-fade-background durationM backwards;

  /* Allow IE11 to vertically center content, without having a hard `height` set. */
  &::after{
    content: '';
    min-height: inherit;
    font-size: 0;
  }

  & > .container {
    position: relative;
    z-index: 1;
    width: 100%;
  }

  & > .grid {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
  }
}

.container {
  & > .content {
    max-width: 64rem;
    margin: 0 auto;
  }
}

.content {
  animation: thank-you-fade-content durationM durationM backwards;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.componentIllustration {
  position: relative;

  /* Make sure the aspect ratio is known, regardless of whether the image finished loading yet. */
  height: 0;
  padding-bottom: 100%;

  & > .content {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.content {
  will-change: opacity, transform;
}

.mousePointerPhobe {
  & > .image {
    width: 100%;
  }
}

.image {
  position: relative;
  top: calc(-1 * sizeXs);

  &.odd {
    top: sizeXs;
  }
}

@keyframes thank-you-fade-background {
  0% {
    background-color: transparent;
  }
}

@keyframes thank-you-fade-content {
  0% {
    opacity: 0;
  }
}
