@import '/theme.css';

.component {
  & > :not(:last-child) {
    margin-bottom: sizeX3s;
  }

  & > .formLabel {
    margin-bottom: sizeX2s;
  }
}

.formLabel {
  display: block;
  font-weight: normal;
}
