@import '/theme.css';

.component {
  text-align: center;
  color: cBlueDark;

  & > .heading {
    margin-bottom: sizeMd;
  }

  & > .cards {
    width: 100%;
  }
}

.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;

  @media viewportLg {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fit, 275px);
    grid-gap: sizeSm;
  }

  /* Also the last child, because we need them vertically centered when displayed side by side. */
  /* When displaying this with display: grid, we remove the margin altogether and use grid-gap to get rid of the last child's margin bottom when they're not displayed side by side. */
  & > * {
    margin: sizeXs;

    @supports (display: grid) {
      margin: 0;
    }
  }
}

.componentCard {
  position: relative;
  z-index: 0;
  background-color: cBlueDark;
  border-radius: 275px;

  & > .circleBackground {
    content: '';
    position: absolute;
    z-index: -1;
    top: -2.5%;
    left: -2.5%;
    width: 105%;
    height: 105%;
    background-color: cBlueDark;
    border-radius: 275px;
    transform: scale(calc(100 / 105));
  }

  & > .imageContainer {
    background-color: cWhite;
    background-repeat: no-repeat;
    background-size: 90%;
    border-radius: 50%;
    bottom: calc(-1 * sizeX4s);
    flex: 0 1 auto;
    height: 30%;
    left: calc(-1 * sizeX4s);
    margin-top: auto;
    padding: sizeX3s;
    position: absolute;
    width: 30%;
  }

  & > .content {
    flex: 1 1 auto;
    height: 100%;
    width: 100%;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: sizeMd;
  border-radius: 50%;
  overflow: hidden;

  & > .title {
    margin-bottom: sizeX2s;
  }

  & > .link {
    position: static;
    text-align: center;
  }

  & > * {
    margin-top: 0;
  }
}

.title {
  font-family: fontFamilyAlt;
  font-weight: fontWeightBold;
  font-size: fontSizeL;
  line-height: lineHeightHeading;
  text-align: center;
  color: cWhite;
}

.imageContainer {
  @media viewportMd {
    display: flex;
    justify-content: flex-end;
  }
}

.backgroundPositionCenter {
  background-position: center;
}

.backgroundPositionBottom {
  background-position: 50% 100%;
}
