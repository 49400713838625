@import '/theme.css';
@import '/utilities.css';

.component {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  & > .trigger {
    width: 100%;
  }
}

.trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: sizeXs 0;
  font-size: fontSizeS;
  font-weight: fontWeightSemiBold;
  cursor: pointer;
  text-align: left;

  & > .icon {
    margin-left: sizeXs;
    flex: 0 0 auto;
  }

  &[disabled] {
    cursor: default;
  }

  @media viewportMd {
    font-size: fontSizeM;
  }
}

.content {
  padding-bottom: sizeXs;

  & > :not(:last-child) {
    margin-bottom: sizeX2s;
  }
}

.icon {
  transition: transform easeInOutExpressive durationS;
}

.trigger[aria-expanded='true'] > .icon {
  transform: rotate(.5turn);
}
